import React from "react"
import type { ReactNode } from "react"

export type CashbackItem = {
  title: ReactNode
  hint: ReactNode
}

export const DEFAULT_ITEMS: [CashbackItem, CashbackItem, CashbackItem] = [
  {
    title: (
      <>
        До 10% <br />с подпиской
      </>
    ),
    hint: (
      <>
        Получайте повышенный кэшбэк до&nbsp;10% на покупки у партнеров на&nbsp;сумму свыше 10 000
        рублей
      </>
    ),
  },
  {
    title: <>От 2 до 5% у партнеров без подписки</>,
    hint: (
      <>
        — 5% за оплату поездки на такси «ЯндексGo».
        <br />
        Покупайте в магазинах-партнерах и&nbsp;получайте выгодный кэшбэк:
        <br /> — 5% за товар дороже 10 000 рублей,
        <br /> — 2%, если сумма покупки не&nbsp;превышает 9999 рублей.
      </>
    ),
  },
  {
    title: "2% за покупки не у партнеров и оплату услуг в приложении",
    hint: <>1% за покупки не у партнеров и&nbsp;оплату услуг ЖКХ в приложении</>,
  },
]
