import React from "react"
import Container from "@ecom/ui/components/Container"
import { CakeImg, CardImg, CardsSvg, HeadphonesImg, ShopSvg, WalletSvg } from "./img"

import { ItemTooltip } from "../ItemTooltip"
import { CashbackItem, DEFAULT_ITEMS } from "./helpers"

import * as styles from "./cashbackHalva.module.scss"

type Props = {
  items?: [CashbackItem, CashbackItem, CashbackItem]
  clickInformerDataLayer?: boolean
  orderNum?: string
}

export const CashbackHalva = ({
  items = DEFAULT_ITEMS,
  clickInformerDataLayer = false,
  orderNum,
}: Props) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container>
      <h2 className={styles.head}>Кэшбэк с Халвой</h2>
      <div className={styles.grid}>
        <div className={styles.block1}>
          <h3 className={styles.title}>
            <ItemTooltip hint={items[0].hint} clickInformerDataLayer={clickInformerDataLayer}>
              <span>
                До 10% <br />с подпиской
              </span>
            </ItemTooltip>
          </h3>
          <ShopSvg className={styles.icon} />
          <HeadphonesImg alt="headphones" className={styles.img} objectPosition="top left" />
        </div>
        <div className={styles.block2}>
          <h3 className={styles.title}>
            <ItemTooltip hint={items[1].hint} clickInformerDataLayer={clickInformerDataLayer}>
              <span>
                От 2 до 5% у партнеров <br /> без подписки
              </span>
            </ItemTooltip>
          </h3>
          <WalletSvg className={styles.icon} />
          <CardImg alt="halva" className={styles.img} objectPosition="top left" />
        </div>
        <div className={styles.block3}>
          <h3 className={styles.title}>
            <ItemTooltip hint={items[2].hint} clickInformerDataLayer={clickInformerDataLayer}>
              <span>2% за покупки не у партнеров и оплату услуг в приложении</span>
            </ItemTooltip>
          </h3>
          <CardsSvg className={styles.icon} />
          <CakeImg alt="cake" className={styles.img} objectPosition="top left" />
        </div>
      </div>
    </Container>
  </section>
)
